<template>
  <div class="overflow-modal">
    <CModal
      content-class-name="upload-document-modal"
      :visible="visible"
      backdrop="static"
      @close="closeModal"
    >

      <div class="upload-document-modal__header">
        <div class="base-modal__top-panel">
          <CIcon class="base-modal__x" name="cil-x" @click="closeModal"></CIcon>
        </div>

        <ModalStatement
          title="Upload Document"
          text="Select a company, project, and scope before uploading your documents" />
      </div>

      <CModalBody class="upload-document-modal__body">
        <div class="upload-document-modal__field upload-document-modal__field_z3">
          <div>
            <div class="upload-document-modal__option">
              <div class="upload-document-modal__option-counter">1</div>
              <span class="upload-document-modal__option-name">Select Company</span>
            </div>
            <p class="upload-document-modal__option-text">
              Start by choosing the company the document(s) are coming from or going to.
            </p>
          </div>

          <div
            class="upload-document-modal__field-search"
            :class="{'upload-document-modal__field-search_opened': companiesListOpened}"
          >
            <Multiselect
              v-model="selectedCompany"
              placeholder="Search by name"
              :can-clear="false"
              :searchable="true"
              :options="companiesList"
              @open="companiesListOpened = true"
              @close="companiesListOpened = false">

              <template #caret>
                <div class="upload-document-modal__field-search-caret">
                  <svg class="dd-caret" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                      fill="currentColor"/>
                  </svg>
                </div>
              </template>

            </Multiselect>
          </div>
        </div>

        <hr />

        <div class="upload-document-modal__field upload-document-modal__field_z2" :aria-disabled="!selectedCompany">
          <div>
            <div class="upload-document-modal__option">
              <div class="upload-document-modal__option-counter">2</div>
              <span class="upload-document-modal__option-name">Select Project</span>
            </div>
            <p class="upload-document-modal__option-text">
              Below is the list of the projects for the specific company selected above..
            </p>
          </div>
          <div
            :class="{'upload-document-modal__field-search_opened': projectsListOpened}"
            class="upload-document-modal__field-search"
          >

            <Multiselect
              v-model="selectedProject"
              :can-clear="false"
              :loading="projectsLoading"
              placeholder="Search by name or number"
              :searchable="true"
              :options="projectsList"
              @open="projectsListOpened = true"
              @close="projectsListOpened = false">

              <template #caret>
                <div class="upload-document-modal__field-search-caret">
                  <svg class="dd-caret" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                      fill="currentColor"/>
                  </svg>
                </div>
              </template>

            </Multiselect>
          </div>
        </div>

        <hr />

        <div class="upload-document-modal__field upload-document-modal__field_z1" :aria-disabled="disabledScopesSelect">
          <div>
            <div class="upload-document-modal__option">
              <div class="upload-document-modal__option-counter">3</div>
              <span class="upload-document-modal__option-name">Select Scope</span>
            </div>
            <p class="upload-document-modal__option-text">
              Here are the scope(s) for this company and project
            </p>
          </div>
          <div
            class="upload-document-modal__field-search"
            :class="{'upload-document-modal__field-search_opened': scopesListOpened}"
          >

            <Multiselect
              v-model="selectedScope"
              :loading="scopeLoading"
              :can-clear="false"
              placeholder="Search by name"
              :searchable="true"
              :options="selectedProjectScopes"
              @open="scopesListOpened = true"
              @close="scopesListOpened = false">

              <template #caret>
                <div class="upload-document-modal__field-search-caret">
                  <svg class="dd-caret" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                      fill="currentColor"/>
                  </svg>
                </div>
              </template>

            </Multiselect>
          </div>
        </div>
      </CModalBody>

      <CModalFooter class="new-scope-modal__footer upload-document-modal__footer">
        <CButton
          color="primary"
          :disabled="disabledButton"
          @click="goToScopePage">
          Continue
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import CSelectSearch from "@/components/CSelectSearch.vue";
import ModalStatement from "@/components/Modals/Partials/ModalStatement.vue";

export default {
  name: "UploadDocumentModal",
  components: {
    ModalStatement,
    CSelectSearch,
    Multiselect
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      companiesList: [],
      companiesListOpened: false,
      selectedCompany: '',
      companiesLoading: false,

      projectsList: [],
      projectsListOpened: false,
      projectsLoading: false,
      selectedProject: '',

      selectedProjectScopes: [],
      selectedScope: '',
      scopesListOpened: false
    }
  },
  methods: {
    closeModal() {
      this.selectedCompany = '';

      this.$emit('close');
    },
    searchCompany(companyName) {
      this.companiesLoading = true;
      this.$http.companies.searchCompany(companyName)
        .then(payload => {
          this.companiesList = payload.data.data.map(item => ({
            value: item.id,
            label: item.name
          }))
        })
        .finally(() => this.companiesLoading = false)
    },

    onSelectedScopeDelete() {
      this.selectedScope = '';
    },
    onSelectedProjectDelete() {
      this.selectedProject = '';
      this.selectedScope = '';
      this.selectedProjectScopes = [];
    },
    onSelectedCompanyDelete() {
      this.selectedCompany = '';
      this.selectedProject = '';
      this.selectedScope = '';

      this.selectedProjectScopes = [];
      this.projectsList = [];
    },
    goToScopePage() {
      const id = this.selectedProject
      const scopeId = this.selectedScope

      this.$router.push({ name: 'ProjectScopeUpload', params: { id, scopeId } })
    }
  },
  computed: {
    disabledButton() {
      return !(this.selectedProject && this.selectedCompany && this.selectedScope)
    },
    disabledScopesSelect() {
      return !this.selectedProject || this.selectedProjectScopes.length === 1
    }
  },
  watch: {
    selectedCompany(val) {
      if (!val) {
        this.onSelectedCompanyDelete();
        return;
      }

      this.onSelectedProjectDelete();
      this.projectsLoading = true;
      this.$http.projects.getCompanyProjects(val)
        .then(payload => {
          this.projectsList = payload.data.data.map(item => ({
            value: item.id,
            label: item.name,
            scopes: item.scopes
          }))
        })
        .finally(() => this.projectsLoading = false)
    },
    selectedProject(val) {
      if (!val) return;
      const project = this.projectsList.find(project => project.value === val);

      this.onSelectedScopeDelete();
      if (project) {
        this.selectedProjectScopes = project.scopes.map(scope => ({
          value: scope.id,
          label: scope.name,
        }))

        if (this.selectedProjectScopes.length === 1) {
          this.selectedScope = this.selectedProjectScopes[0].value
        }
      }
    }
  },
  created() {
    this.searchCompany('')
  }
}
</script>

<style lang="scss">
.upload-document-modal {
  width: 560px !important;

  @media(max-height: 840px){
    height: 90vh;
    overflow-y: auto;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 8px;

    &-counter {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;
      border-radius: 200px;
      background: #0068AD;
      font-size: 10px !important;
      color: #fff;
    }

    &-name {
      font-family: "Segoe UI", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #1C262F;
    }

    &-text {
      font-size: 14px;
      color: #677A89;
      padding-left: 24px;
      margin: 0;
    }
  }

  &__header {
    padding: 28px 28px 0 28px;
  }

  &__footer {
    @media(max-height: 840px){
      padding: 5px;
      margin-top: 5px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(max-height: 840px){
      margin-top: 15px;
      gap: 5px;
    }

    margin-top: 32px;
    padding: 5px 28px;
  }

  &__field {

    &_z3 {
      z-index: 103;
    }

    &_z2 {
      z-index: 102;
    }

    &_z1 {
      z-index: 101;
    }

    &-search {
      display: flex;
      position: relative;
      margin-top: 16px;

      &_opened {

        & .dd-caret {
          transform: rotate(180deg);
        }
      }

      &-caret {
        position: relative;
        display: flex;
        align-items: center;
        width: 25px;
        margin-right: 10px;

        & svg {
          color: #0895D1;
        }
      }

      //& .is-active {
      //  outline: 4px solid #ABE7FA;
      //  border-radius: 8px;
      //}
      //
      //& .is-pointed {
      //
      //  &:hover {
      //    background: #0068AD !important;
      //  }
      //
      //  & span {
      //    color: white !important;
      //  }
      //}
      //
      //& .is-selected {
      //  background: #0068AD;
      //
      //  &:hover {
      //    background: #0068AD !important;
      //  }
      //
      //  & span {
      //    color: white !important;
      //  }
      //}

      & .multiselect {
        --option-active: #0068AD;

        border-radius: 8px;
        border: 1px solid #9FAFBC;

        &-wrapper {
          min-height: 56px;
        }

        &-search {
          position: unset;
          border-radius: 8px;
          color: #1C262F;
        }

        &-options {
          width: 100%;

          & li {
            width: 100%;

            & span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        &-option {
          color: #677A89;

          &.is-pointed {
            background: var(--ms-group-label-bg-pointed, #D1D5DB);
            color: var(--ms-group-label-color-pointed, #374151);
          }

          &.is-selected {
            background: var(--option-active);
            color: white;
          }

          &.is-disabled {
            background: var(--ms-group-label-bg-disabled, #F3F4F6);
            color: var(--ms-group-label-color-disabled, #D1D5DB);
            cursor: not-allowed;
          }

          &.is-selected.is-pointed {
            background: var(--option-active);
            color: white;
          }

          &.is-selected.is-disabled {
            background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
            color: var(--ms-group-label-color-selected-disabled, #D1FAE5);
          }
        }
      }
    }

    &-clear {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #1C262F;
      margin-bottom: 24px;
    }
  }

  //& .CSelectSearch__value {
  //  display: flex;
  //  align-items: center;
  //
  //  flex: 1;
  //  height: 56px;
  //  border: none;
  //  padding-right: 30px;
  //  padding-left: 16px;
  //}
  //
  //& .CSelectSearch__arrow {
  //  right: 18px;
  //}
  //
  //& .CSelectSearch__text {
  //  font-weight: 400 !important;
  //  font-size: 16px !important;
  //  color: #677A89 !important;
  //}
  //
  //& .CSelectSearch__searchField {
  //  font-weight: 400 !important;
  //  font-size: 16px !important;
  //  color: #677A89 !important;
  //}
}
</style>
